@use "../utilities" as *;

/**----------------------------------------
START: Theme Blog CSS
----------------------------------------*/

.post-card {
    .post-content {
        background-color: var(--tj-color-theme-bg-dark-light);
        padding: 30px;
        .post-content-title {
            max-width: 420px;
            width: 100%;
            line-height: 1.6em;
            margin: 15px 0 20px 0;
            text-decoration: underline;
            transition: all 0.3s ease-in-out;
            &:hover {
                color: var(--tj-color-theme-primary);
            }
            br {
                @media #{$md} {
                    display: none;
                }
            }
            @media #{$md} {
                font-size: 22px;
            }
        }
        p {
            max-width: 450px;
            width: 100%;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .post-thumb {
        transform: scale(1);
        transition: all 0.3s ease-in-out;
        overflow: hidden;
    }
    &:hover .post-thumb img {
        transform: scale(1.1);
    }
}

.post-meta {
    ul {
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 30px;
        li {
            font-size: 16px;
            a {
                transition: 0.4s;
                &:hover {
                    color: var(--tj-color-theme-primary);
                }
            }
            i {
                color: var(--tj-color-theme-primary);
                margin-right: 8px;
                position: relative;
                top: 3px;
            }
        }
    }
}
.post-items {
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    grid-gap: 21px;
    .post-item {
        .post-content {
            padding: 40px 30px;
            h3 {
                margin-bottom: 0;
            }
        }
    }
}

.blog-2 {
    padding-bottom: 95px;
    @media #{$md} {
        padding-bottom: 45px;
    }
    @media #{$sm} {
        padding-bottom: 35px;
    }
    .post-card {
        margin-bottom: 25px;
        .post-content {
            .post-content-title {
                @media #{$md} {
                    font-size: 20px;
                }
            }
        }
    }
}

.blog-wrapper {
    @media #{$md} {
        margin-bottom: 60px;
    }
    .tj-blog {
        .blog-details-thumb {
            margin-bottom: 40px;
            border-radius: 5px;
            overflow: hidden;
        }
        .tj-blog-title {
            font-size: 35px;
            margin-bottom: 20px;
        }
        .post-meta {
            margin-bottom: 15px;
        }
        .desc {
            margin-bottom: 20px;
        }
        .blog-details-box {
            display: flex;
            align-items: center;
            column-gap: 30px;
            margin-bottom: 50px;
            @media #{$md} {
                flex-direction: column;
                align-items: baseline;
                row-gap: 25px;
            }
            .blog-details-list {
                list-style: none;
                li {
                    font-size: 18px;
                    color: var(--tj-color-common-white);
                    line-height: 1;
                    display: flex;
                    align-items: center;
                    column-gap: 10px;
                    margin-bottom: 20px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                    i {
                        font-size: 25px;
                        margin-top: 4px;
                    }
                }
            }
        }
        .details-para {
            max-width: 765px;
            width: 100%;
            margin-bottom: 50px;
        }
        blockquote {
            background-color: var(--tj-color-theme-bg-dark-light);
            padding: 40px 35px;
            margin-bottom: 40px;
            img {
                margin-bottom: 20px;
            }
            p {
                margin-bottom: 45px;
            }
            span {
                font-size: 24px;
                color: var(--tj-color-theme-primary);
                padding-left: 55px;
                position: relative;
                line-height: 1;
                &:before {
                    background-color: var(--tj-color-theme-primary);
                    content: "";
                    width: 40px;
                    height: 2px;
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }
        .comment-wrap {
            margin-bottom: 50px;
            .comment-header {
                font-size: 30px;
                line-height: 1;
                position: relative;
                margin-bottom: 60px;
                &:before {
                    background-color: var(--tj-color-theme-primary);
                    content: "";
                    width: 70px;
                    height: 2px;
                    position: absolute;
                    bottom: -25px;
                    left: 0;
                }
            }
            .comment-box {
                display: flex;
                column-gap: 25px;
                margin-bottom: 30px;
                padding-bottom: 30px;
                border-bottom: 1px solid var(--tj-color-border-5);
                @media #{$sm} {
                    flex-direction: column;
                    align-items: baseline;
                    row-gap: 25px;
                }
                &.box-2 {
                    padding-left: 35px;
                    margin-bottom: 0;
                    padding-bottom: 0;
                    border: none;
                }
                .comment-thumb {
                    width: 120px;
                    height: 120px;
                    img {
                        max-width: inherit;
                    }
                }
                .comment-content {
                    .comment-title {
                        font-family: var(--tj-ff-body);
                        color: var(--tj-color-grey-9);
                        font-size: 18px;
                        line-height: 1;
                        font-weight: 500;
                        margin-bottom: 15px;
                    }
                    span {
                        font-size: 16px;
                        margin-bottom: 10px;
                        display: block;
                    }
                    p {
                        max-width: 610px;
                        margin-bottom: 25px;
                    }
                    .reply {
                        color: var(--tj-color-theme-primary);
                        font-size: 16px;
                        padding: 5px 20px;
                        border: 1px solid var(--tj-color-theme-primary);
                    }
                }
            }
        }
        .blog-details-form {
            .form-header {
                font-size: 30px;
                line-height: 1;
                position: relative;
                margin-bottom: 60px;
                &:before {
                    background-color: var(--tj-color-theme-primary);
                    content: "";
                    width: 70px;
                    height: 2px;
                    position: absolute;
                    bottom: -25px;
                    left: 0;
                }
            }
            .beauly-contact-form {
                max-width: inherit;
                margin: 0;
                .form-group {
                    .form-control {
                        background-color: transparent;
                        border: 1px solid var(--tj-color-grey-9);
                    }
                }
                .tj-primary-btn {
                    width: inherit;
                }
            }
        }
    }
}

.sidebar-item {
    background-color: var(--tj-color-theme-bg-dark-light);
    padding: 30px;
    margin-bottom: 30px;
    @media #{$md} {
        &.item-2 {
            margin-bottom: 0;
        }
    }
    .search-form {
        position: relative;
        .form-control {
            background-color: transparent;
            font-size: 16px;
            border-radius: 0;
            box-shadow: none;
            outline: none;
            height: 50px;
            border: 1px solid var(--tj-color-theme-primary);
            padding-right: 40px;
            color: var(--tj-color-common-white);
            @include tp-placeholder {
                color: var(--tj-color-grey-10);
            }
        }
        .search-btn {
            background-color: var(--tj-color-theme-primary);
            color: var(--tj-color-common-white);
            position: absolute;
            right: 0;
            top: 0;
            width: 50px;
            height: 100%;
            font-size: 15px;
        }
    }
    .sidebar-title {
        font-size: 30px;
        line-height: 1;
        position: relative;
        margin-bottom: 60px;
        &:before {
            background-color: var(--tj-color-theme-primary);
            content: "";
            width: 70px;
            height: 2px;
            position: absolute;
            bottom: -25px;
            left: 0;
        }
    }
    .thumb-post {
        li {
            display: inline-flex;
            align-items: center;
            justify-content: space-between;
            &:not(:last-of-type) {
                margin-bottom: 30px;
            }
            .thumb {
                max-width: 110px;
                flex-shrink: 0;
                margin: 0 15px 0 0;
                position: relative;
                display: inline-block;
                vertical-align: top;
                max-width: 100%;
                overflow: hidden;
            }
            .thumb-post-info {
                .post-meta {
                    margin-bottom: 5px;
                }
                .post-meta {
                    margin-bottom: 15px;
                    li {
                        &:not(:last-of-type) {
                            margin-bottom: 0;
                        }
                    }
                }
                .thumb-post-title {
                    font-size: 18px;
                    font-family: var(--tj-ff-body);
                    line-height: 1.5em;
                    max-width: 200px;
                    width: 100%;
                    margin-bottom: 0;
                    a {
                        transition: all 0.3s ease-in-out;
                        &:hover {
                            color: var(--tj-color-theme-primary);
                        }
                    }
                }
            }
        }
    }
    .category-list {
        li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            a {
                font-size: 16px;
                color: var(--tj-color-grey-9);
                transition: all 0.3s ease-in-out;
                &:hover {
                    color: var(--tj-color-theme-primary);
                }
            }
            span {
                font-size: 16px;
                color: var(--tj-color-theme-primary);
            }
            &:not(:last-of-type) {
                margin-bottom: 25px;
                padding-bottom: 25px;
                border-bottom: 1px solid var(--tj-color-border-5);
            }
        }
    }
    .tags {
        li {
            display: inline-flex;
            a {
                background-color: transparent;
                color: var(--tj-color-grey-1);
                text-transform: capitalize;
                font-size: 16px;
                padding: 8px 20px;
                margin: 0 10px 10px 0;
                border: 1px solid var(--tj-color-grey-9);
                transition: all 0.3s ease-in-out;
                &:hover {
                    background-color: var(--tj-color-theme-primary);
                    color: var(--tj-color-common-white);
                    border: 1px solid var(--tj-color-theme-primary);
                }
            }
        }
    }
}

/* !END: Theme Blog CSS */
