@use "../utilities" as *;

/**----------------------------------------
START: Theme Project Filter CSS
----------------------------------------*/
.project-filter-section {
    padding-bottom: 90px;
    @media #{$md} {
        padding-bottom: 40px;
    }
    @media #{$sm} {
        padding-bottom: 30px;
    }
    .filter-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 60px;
        @media #{$lg} {
            flex-direction: column;
            row-gap: 40px;
        }
        @media #{$md} {
            flex-direction: column;
            row-gap: 20px;
        }
        @media #{$sm} {
            flex-direction: column;
            row-gap: 20px;
            margin-bottom: 40px;
        }
        .project-filter {
            list-style: none;
            li {
                font-size: 18px;
                display: inline-flex;
                cursor: pointer;
                line-height: 1;
                margin-right: 40px;
                @media #{$xl} {
                    font-size: 16px;
                    margin-right: 20px;
                }
                &:last-child {
                    margin-right: 0;
                }
                &.active {
                    color: var(--tj-color-theme-primary);
                    border-bottom: 1px solid var(--tj-color-theme-primary);
                }
                @media #{$sm} {
                    margin-right: 20px;
                    margin-bottom: 15px;
                }
                @media #{$md} {
                    margin-bottom: 20px;
                }
            }
        }
        .project-location-search {
            .ddl-select {
                background-color: transparent;
                font-size: 18px;
                border: 1px solid var(--tj-color-theme-primary);
                width: 412px;
                height: 60px;
                line-height: 60px;
                border-radius: 0;
                @media #{$sm} {
                    width: 250px;
                }
                &:after {
                    height: 12px;
                    width: 12px;
                    margin-top: -8px;
                    right: 25px;
                }
                .list {
                    width: 100%;
                    border-radius: 0;
                    li {
                        background-color: var(--tj-color-theme-primary);
                        font-size: 16px;
                        color: var(--tj-color-common-white);
                        transition: all 0.3s ease-in-out;
                        &:hover {
                            background-color: var(--tj-color-common-black);
                        }
                    }
                }
            }
        }
    }
}
/* !END: Theme Project Filter CSS */
