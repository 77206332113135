@font-face {
    font-family: "flaticon_beauly";
    src: url("../fonts/flaticon_beauly.ttf?6ff65d437eb2487061a5eb47f56a69d9") format("truetype"),
        url("../fonts/flaticon_beauly.woff?6ff65d437eb2487061a5eb47f56a69d9") format("woff"),
        url("../fonts/flaticon_beauly.woff2?6ff65d437eb2487061a5eb47f56a69d9") format("woff2"),
        url("../fonts/flaticon_beauly.eot?6ff65d437eb2487061a5eb47f56a69d9#iefix") format("embedded-opentype"),
        url("../fonts/flaticon_beauly.svg?6ff65d437eb2487061a5eb47f56a69d9#flaticon_beauly") format("svg");
}

i[class^="flaticon-"]:before,
i[class*=" flaticon-"]:before {
    font-family: flaticon_beauly !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.flaticon-house:before {
    content: "\f101";
}

.flaticon-location:before {
    content: "\f102";
}

.flaticon-swimming-pool:before {
    content: "\f103";
}

.flaticon-wifi-router:before {
    content: "\f104";
}

.flaticon-electric-car:before {
    content: "\f105";
}

.flaticon-weight:before {
    content: "\f106";
}

.flaticon-play:before {
    content: "\f107";
}

.flaticon-right:before {
    content: "\f108";
}

.flaticon-add:before {
    content: "\f109";
}

.flaticon-minus:before {
    content: "\f10a";
}

.flaticon-quotation:before {
    content: "\f10b";
}

.flaticon-apartment:before {
    content: "\f10c";
}

.flaticon-public-toilet:before {
    content: "\f10d";
}

.flaticon-sketch:before {
    content: "\f10e";
}

.flaticon-kitchen:before {
    content: "\f10f";
}

.flaticon-house-1:before {
    content: "\f110";
}

.flaticon-right-arrow-1:before {
    content: "\f111";
}

.flaticon-chevron:before {
    content: "\f112";
}

.flaticon-house-2:before {
    content: "\f113";
}

.flaticon-home:before {
    content: "\f114";
}

.flaticon-full-screen:before {
    content: "\f115";
}

.flaticon-trophy:before {
    content: "\f116";
}

.flaticon-health-care:before {
    content: "\f117";
}

.flaticon-pin:before {
    content: "\f118";
}

.flaticon-double-bed:before {
    content: "\f119";
}

.flaticon-bathroom:before {
    content: "\f11a";
}

.flaticon-mail:before {
    content: "\f11b";
}

.flaticon-menu:before {
    content: "\f11c";
}

.flaticon-linkedin:before {
    content: "\f11d";
}

.flaticon-signature:before {
    content: "\f11e";
}

.flaticon-chat:before {
    content: "\f11f";
}

.flaticon-courier:before {
    content: "\f120";
}

.flaticon-phone-call:before {
    content: "\f121";
}

.flaticon-user:before {
    content: "\f122";
}

.flaticon-calendar:before {
    content: "\f123";
}

.flaticon-chat-box:before {
    content: "\f124";
}

.flaticon-arrow-button-1:before {
    content: "\f125";
}

.flaticon-right-arrow-1:before {
    content: "\f126";
}

.flaticon-speech-bubble:before {
    content: "\f127";
}

.flaticon-share:before {
    content: "\f128";
}

.flaticon-envelope:before {
    content: "\f129";
}

.flaticon-map:before {
    content: "\f12a";
}

.flaticon-link-1:before {
    content: "\f12b";
}

.flaticon-quote-1:before {
    content: "\f12c";
}

.flaticon-refresh:before {
    content: "\f12d";
}

.flaticon-left-arrow:before {
    content: "\f12e";
}

.flaticon-link:before {
    content: "\f12f";
}

.flaticon-calendar-2:before {
    content: "\f130";
}

.flaticon-arrow-down:before {
    content: "\f131";
}

.flaticon-send-message:before {
    content: "\f132";
}

.flaticon-play-button:before {
    content: "\f133";
}

.flaticon-support-1:before {
    content: "\f134";
}

.flaticon-arrow-button:before {
    content: "\f135";
}

.flaticon-play-button-2:before {
    content: "\f136";
}

.flaticon-blind:before {
    content: "\f137";
}

.flaticon-downloading-data:before {
    content: "\f138";
}

.flaticon-award:before {
    content: "\f139";
}

.flaticon-checkmark:before {
    content: "\f13a";
}

.flaticon-double-quotes:before {
    content: "\f13b";
}

.flaticon-left-chevron:before {
    content: "\f13c";
}

.flaticon-checked:before {
    content: "\f13d";
}

.flaticon-play-button-1:before {
    content: "\f13e";
}

.flaticon-minus:before {
    content: "\f13f";
}

.flaticon-left-quote:before {
    content: "\f140";
}

.flaticon-visible:before {
    content: "\f141";
}

.flaticon-success:before {
    content: "\f142";
}

.flaticon-email:before {
    content: "\f143";
}

.flaticon-back:before {
    content: "\f144";
}

.flaticon-calendar-3:before {
    content: "\f145";
}

.flaticon-right-quote:before {
    content: "\f146";
}

.flaticon-check-box:before {
    content: "\f147";
}

.flaticon-email-1:before {
    content: "\f148";
}

.flaticon-done:before {
    content: "\f149";
}

.flaticon-quote-left:before {
    content: "\f14a";
}

.flaticon-right-quotation-mark:before {
    content: "\f14b";
}

.flaticon-check-box-1:before {
    content: "\f14c";
}

.flaticon-airplane:before {
    content: "\f14d";
}

.flaticon-support:before {
    content: "\f14e";
}

.flaticon-quote-2:before {
    content: "\f14f";
}

.flaticon-user:before {
    content: "\f150";
}

.flaticon-calendar-1:before {
    content: "\f151";
}

.flaticon-double-arrow:before {
    content: "\f152";
}

.flaticon-cloud-computing:before {
    content: "\f153";
}

.flaticon-targeting:before {
    content: "\f154";
}

.flaticon-location:before {
    content: "\f155";
}

.flaticon-setting-1:before {
    content: "\f156";
}

.flaticon-back-1:before {
    content: "\f157";
}

.flaticon-location-1:before {
    content: "\f158";
}

.flaticon-double-arrow-1:before {
    content: "\f159";
}

.flaticon-24-hours-support:before {
    content: "\f15a";
}

.flaticon-calendar:before {
    content: "\f15b";
}

.flaticon-right-arrow-2:before {
    content: "\f15c";
}

.flaticon-checked-1:before {
    content: "\f15d";
}

.flaticon-double-arrow-2:before {
    content: "\f15e";
}

.flaticon-phone:before {
    content: "\f15f";
}

.flaticon-setting:before {
    content: "\f160";
}

.flaticon-next:before {
    content: "\f161";
}

.flaticon-external-link:before {
    content: "\f162";
}

.flaticon-plus:before {
    content: "\f163";
}

.flaticon-telephone:before {
    content: "\f164";
}

.flaticon-clock:before {
    content: "\f165";
}

.flaticon-search:before {
    content: "\f166";
}

.flaticon-arrow-up:before {
    content: "\f167";
}

.flaticon-left-chevron-1:before {
    content: "\f168";
}

.flaticon-quote:before {
    content: "\f169";
}

.flaticon-play-button-arrowhead:before {
    content: "\f16a";
}

.flaticon-double-arrow-3:before {
    content: "\f16b";
}

.flaticon-right:before {
    content: "\f16c";
}

.flaticon-play:before {
    content: "\f16d";
}

.flaticon-right-arrow:before {
    content: "\f16e";
}

.flaticon-people:before {
    content: "\f16f";
}

.flaticon-check:before {
    content: "\f170";
}

.flaticon-loupe:before {
    content: "\f171";
}
