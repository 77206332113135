
 //Bootstrap default variables and functions
@import "bootstrap/scss/mixins/banner";
@include bsBanner("");


// scss-docs-start import-stack
// Configuration
@import "bootstrap/scss/functions";

@import "bootstrap/scss/variables";
@import "bootstrap/scss/variables-dark";
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";



// domain specific css and colors are included below

$gray: $gray-500;
//$body-bg: #2b2c30;// TODO: Move this to the website css
/**
"domainStyle" is a reference to the generated scss file inside the domain folder.
It is aliased in frontend/frontend-5.0/webpack.config.js.
It consists of bootstrap variables, global- and section-styling.
 */
@import "domainStyle"; // Ignore the warnings. Its an alias to the domain set by webpack.

// Bootstrap variables and domain specific custom colors


// Layout & components
@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
//@import "bootstrap/scss/dropdown";
//@import "bootstrap/scss/button-group";
//@import "bootstrap/scss/nav";
//@import "bootstrap/scss/navbar";
//@import "bootstrap/scss/card";
@import "bootstrap/scss/accordion";
//@import "bootstrap/scss/breadcrumb";
//@import "bootstrap/scss/pagination";
//@import "bootstrap/scss/badge";
//@import "bootstrap/scss/alert";
@import "bootstrap/scss/progress";
@import "bootstrap/scss/list-group";
@import "bootstrap/scss/close";
//@import "bootstrap/scss/toasts";
@import "bootstrap/scss/modal";
//@import "bootstrap/scss/tooltip";
//@import "bootstrap/scss/popover";
@import "bootstrap/scss/carousel";
//@import "bootstrap/scss/spinners";
//@import "bootstrap/scss/offcanvas";
//@import "bootstrap/scss/placeholders";

// Helpers
@import "bootstrap/scss/helpers";

// Utilities
@import "bootstrap/scss/utilities/api";
// scss-docs-end import-stack


 // Fonts
 $fonts-path: 'assets/fonts';
 @import './fonts/fonts.scss';
 @import '~assets/fonts/nwn-icons/icons';

@import './styling/media';

// Direct font path to absolute fontawesome webfont folder
$fa-font-path: "@fortawesome/fontawesome-pro/webfonts";
@import '~@fortawesome/fontawesome-pro/scss/fontawesome';
// import fontawesome types
@import '~@fortawesome/fontawesome-pro/scss/regular';
@import '~@fortawesome/fontawesome-pro/scss/brands';
@import '~@fortawesome/fontawesome-pro/scss/solid';

@import 'swiper/swiper-bundle.css';
@import 'jquery-datetimepicker/jquery.datetimepicker.css';
@import '../assets/css/animate.css';
@import '../assets/css/meanmenu.css';
@import '../assets/css/owl-carousel.css';
@import '../assets/css/flaticon-beauly.css';
@import '../assets/css/magnific-popup.css';
@import '../assets/css/odometer.min.css';
@import '../assets/css/nice-select.css';


 // Beauly specific css
@import '../assets/sass/main';
