
// Font classNames
.font-lucida-console { font-family: 'Lucida Console', Monaco, monospace; }
.font-times-new-roman { font-family: 'Times New Roman', serif; }
.font-gotham { font-family: 'Gotham', serif; }
.font-trueno { font-family: 'Trueno', serif; }
.font-roboto { font-family: 'Roboto', serif; }

// Google fonts
//.font-roboto
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100;1,300;1,400&display=swap');

// Custom fonts
// Path to ./fonts from frontend/frontend-*/src
$fontsPath: "fonts";

//.font-gotham
@font-face {
  font-family: "Gotham";
  font-display: swap;
  src: url('#{$fontsPath}/Gotham-Bold.woff2') format("woff2"), url('#{$fontsPath}/Gotham-Bold.woff') format("woff");
}

//.font-trueno
@font-face {
  font-family: "Trueno";
  font-display: swap;
  src: url("#{$fontsPath}/TruenoLt.woff2") format("woff2"), url("#{$fontsPath}/TruenoLt.woff") format("woff");
}
