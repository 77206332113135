@use "../utilities" as *;

/**----------------------------------------
START: Theme Interactive Image CSS
----------------------------------------*/
@include keyframes(pulsate) {
	0% {
		transform: scale(0.1, 0.1);
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	100% {
		transform: scale(1.2, 1.2);
		opacity: 0;
	}
}

.tj_interactive_image {
	display: block;
	position: relative;
	z-index: 1;
	max-height: 700px;

	.tj_interactive_image_media {
		position: relative;
		z-index: 1;

		&::before {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background-color: var(--tj-color-common-black);
			opacity: 0.4;
		}

		img {
			width: 100%;
		}

		@media #{$xs, $sm, $md, $lg, $xl} {
			display: inline-block;
		}
	}

	.tj_interactive_image_item_dot {
		display: flex;
		align-items: center;
		text-align: center;
		justify-content: center;
		line-height: 2;
		font-family: var(--tj-ff-body);
		font-size: 14px;
		font-weight: 600;
		text-align: center;
		border-radius: 50%;
		width: 2em;
		height: 2em;
		cursor: pointer;
		background: var(--tj-color-common-white);
		color: var(--tj-color-theme-bg-dark-light);
		transform: scale(1);
		transition: all 800ms ease;

		&::after {
			content: "";
			background: rgba(255, 255, 255, 0.5);
			width: 4em;
			height: 4em;
			z-index: -2;
			position: absolute;
			top: -20%;
			left: -20%;
			border-radius: 50%;
			opacity: 0;
			animation: pulsate 1.2s ease-out infinite;
		}

		@media #{$md} {
			width: 30px !important;
			height: 30px !important;

			&::after {
				width: 3em;
				height: 3em;
			}
		}
		@media #{$xs, $sm} {
			font-size: 12px;
			width: 25px !important;
			height: 25px !important;

			&::after {
				width: 2.5em;
				height: 2.5em;
			}
		}

		&.tj_interactive_image_item_dot_on {
			background: var(--tj-color-theme-primary);
			color: var(--tj-color-common-white);

			&::after {
				animation: none;
			}
		}

		.tj_interactive_image_item_dot_tooltip {
			font-size: 18px;
			line-height: 1;
			font-family: var(--tj-ff-heading);
			text-transform: capitalize;
			width: 150px;
			position: absolute;
			margin-top: -8em;
			padding: 15px 10px;
			background: var(--tj-color-theme-bg-dark-light);
			color: var(--tj-color-common-white);
			transition: all 300ms ease;
			opacity: 0;

			&::after {
				content: "";
				display: block;
				border: 10px solid var(--tj-color-theme-bg-dark-light);
				border-right-color: transparent;
				border-left-color: transparent;
				border-bottom-color: transparent;
				position: absolute;
				left: 50%;
				bottom: -20px;
				margin: 0 -0.5em;
			}

			span {
				display: block;
				font-size: 15px;
				margin-top: 10px;
			}
		}

		&:hover {
			.tj_interactive_image_item_dot_tooltip {
				opacity: 1;
			}
		}
	}

	.tj_interactive_image_content {
		background-color: var(--tj-color-theme-bg-dark-light);
		position: absolute;
		left: 15%;
		top: 50%;
		transform: translateY(-50%);
		z-index: 3;
		max-width: 450px;
		width: 100%;
		padding: 30px;
		border-radius: 5px;

		@media #{$xxl} {
			left: 10%;
		}
		@media #{$xl} {
			left: 6%;
		}
		@media #{$lg} {
			left: 1%;
			max-width: 400px;
		}

		@media #{$md, $sm, $xs} {
			position: relative;
			top: initial;
			left: 50%;
			transform: translateY(0) translateX(-50%);
			margin: 60px 0;
		}

		.tj_interactive_image_item {
			padding: 20px 0;
			text-align: left;
			cursor: pointer;

			&:not(:last-child) {
				border-bottom: 1px solid var(--tj-color-border-5);
			}
			&:first-child {
				padding-top: 0;
			}
			&:last-child {
				padding-bottom: 0;
			}

			.tj_interactive_image_item_title,
			.tj_interactive_image_item_number {
				font-size: 20px;
				line-height: 1.1;
				font-family: var(--tj-ff-heading);
				color: var(--tj-color-common-white);
				text-transform: capitalize;
				position: relative;
				transition: 350ms ease all;
			}
			.tj_interactive_image_item_title {
				padding-right: 20px;

				&::after {
					content: "\f067";
					font-family: "Font Awesome 6 Pro";
					font-size: 16px;
					font-weight: 500;
					line-height: 1;
					width: auto;
					height: auto;
					position: absolute;
					right: 0;
					top: 4px;
				}
			}
			.tj_interactive_image_item_number {
				float: left;
				padding-right: 15px;
			}

			.tj_interactive_image_item_text {
				padding: 0;
				display: block;
				overflow: hidden;
				height: 0;
				opacity: 0;
				transition: 350ms ease all;
				width: 95%;

				@media #{$xs, $sm, $md, $lg} {
					width: 100%;
				}
			}

			&.on {
				.tj_interactive_image_item_title,
				.tj_interactive_image_item_number {
					color: var(--tj-color-theme-primary);
				}
				.tj_interactive_image_item_title {
					&::after {
						content: "\f068";
					}
				}
				.tj_interactive_image_item_text {
					display: block;
					opacity: 1;
					height: auto;
					padding-top: 15px;
				}
			}
		}
	}
}
/* !END: Theme Interactive Image CSS */
