// after before
@mixin theme-afbf {
  position: absolute;
  content: "";
}

// bg color
@mixin bg-color($color, $opacity) {
  background: rgba($color, $opacity);
}

// placeholder input
@mixin tp-placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}


// keyframes
@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}

//background
@mixin background {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}


// sentence case
@mixin sentence-case() {
  text-transform: lowercase;
  &:first-letter {
    text-transform: uppercase;
  }
}

// Flexbox display
@mixin flexbox() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

