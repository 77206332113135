@use "../utilities" as *;

/**----------------------------------------
START: Theme Amenities CSS
----------------------------------------*/
.amenities-section {
    @media #{$md} {
        padding-bottom: 30px;
    }
    .section-heading {
        margin-bottom: 90px;
        @media #{$md} {
            margin-bottom: 40px;
        }
        .sub-heading {
            margin-bottom: 15px;
        }
        .section-title {
            margin-bottom: 20px;
            @media #{$md} {
                width: 100%;
                line-height: 1.2;
            }
        }
    }
}

.amenities-item {
    @media #{$md} {
        margin-bottom: 40px;
    }
    .amenities-icon {
        color: var(--tj-color-theme-primary);
        font-size: 75px;
        height: 75px;
        width: 75px;
        line-height: 1;
        display: inline-block;
        transform: rotateY(0deg);
        transition: 0.6s all ease-in-out;
    }
    .amenities-item-title {
        margin: 25px 0 25px 0;
        line-height: 1;
    }
    p {
        &:last-child {
            margin-bottom: 0;
        }
    }
    &:hover .amenities-icon {
        transform: rotateY(360deg);
    }
}

.amenities-2 {
    padding-bottom: 95px;
    @media #{$md} {
        padding-bottom: 45px;
    }
    @media #{$sm} {
        padding-bottom: 35px;
    }
    .section-heading {
        @media #{$md} {
            padding-bottom: 25px;
        }
        .section-title {
            line-height: 1.2;
        }
    }
}

.amenities-box {
    position: relative;
    z-index: 1;
    padding: 60px 20px;
    border: 1px solid var(--tj-color-theme-primary);
    margin-bottom: 25px;
    overflow: hidden;
    @media #{$lg, $md, $sm, $xs} {
        padding: 40px 20px;
    }
    .amenities-icon {
        color: var(--tj-color-theme-primary);
        font-size: 75px;
        height: 75px;
        width: 75px;
        line-height: 1;
        display: inline-block;
        margin-bottom: 30px;
        transform: rotateY(0deg);
        transition: 0.6s all ease-in-out;
    }
    .amenities-item-title {
        margin-bottom: 25px;
        line-height: 1;
        @media #{$lg} {
            font-size: 20px;
        }
    }
    .amenities-thumb {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        transition: all 0.3s ease-in-out;
        visibility: hidden;
        opacity: 0;
        z-index: -1;
    }
    .overlay {
        background-color: rgba(0, 0, 0, 0.6);
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        transition: all 0.3s ease-in-out;
        visibility: hidden;
        opacity: 0;
        z-index: -1;
    }
    p {
        &:last-child {
            margin-bottom: 0;
        }
    }
    &:hover .overlay,
    &:hover .amenities-thumb {
        visibility: visible;
        opacity: 1;
    }
    &:hover .amenities-icon {
        transform: rotateY(360deg);
    }
}
/* !END: Theme Amenities CSS */
