@use "../utilities" as *;

/**----------------------------------------
START: Theme Page Header CSS
----------------------------------------*/
.page-header {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    width: 100%;
    height: 520px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media #{$md, $sm, $xs} {
        height: 450px;
    }
    .page-header-content {
        margin-top: 50px;
        h1 {
            font-size: 70px;
            margin-bottom: 15px;
            @media #{$md} {
                font-size: 60px;
            }
            @media #{$sm} {
                font-size: 48px;
            }
        }
        p {
            max-width: 560px;
            margin: 0 auto;
            width: 100%;
        }
    }
}

/* !END: Theme Page Header CSS */
