@use "../utilities" as *;

/**----------------------------------------
START: Theme Faq CSS
----------------------------------------*/
.faq-section {
	// background-repeat: no-repeat;
	// background-size: cover;
	// background-position: center center;
	width: 100%;
	height: 700px;
	position: relative;
	@media #{$md} {
		height: auto;
	}

	&.interactive-image {
		.icon-radio-checked {
			display: block;
		}
		.hotspot {
			font-size: inherit;

			&::before {
				content: "";
			}
			> span {
				background-color: var(--tj-color-common-white);
				color: var(--tj-color-theme-bg-dark-light);
				width: 40px;
				height: 40px;
				display: inline-flex;
				align-items: center;
				justify-content: center;
				border-radius: 50%;
				line-height: 1;
				font-family: var(--tj-ff-body);
				font-size: var(--tj-fs-body);
				font-weight: var(--tj-fw-sbold);
				animation: ripple_white 2s linear infinite;
			}
			&:hover,
			&.active {
				span {
					background-color: var(--tj-color-theme-primary);
					color: var(--tj-color-common-white);
				}
			}
		}
		.item {
			margin-top: 10px;
			display: block !important;
			visibility: hidden;
		}
		.hotspot {
			&:hover + .item {
				visibility: visible;
			}
		}
	}

	.location-mark {
		position: absolute;
		width: 100%;
		height: 100%;
		@media #{$md} {
			display: none;
		}
		@media #{$lg} {
			display: none;
		}
		& > div {
			position: absolute;
			right: 28%;
			top: 50%;
			cursor: pointer;
			-webkit-transition: all 0.4s ease-in-out;
			-o-transition: all 0.4s ease-in-out;
			transition: all 0.4s ease-in-out;
			-webkit-animation: ripple_white 2s linear infinite;
			animation: ripple_white 2s linear infinite;
			border-radius: 50%;
			&:nth-child(2) {
				right: 40%;
				top: 60%;
			}
			&:nth-child(3) {
				right: 50%;
				top: 30%;
			}
			&:nth-child(4) {
				right: 57%;
				top: 67%;
			}
			& > span {
				background-color: var(--tj-color-common-white);
				color: var(--tj-color-theme-bg-dark-light);
				font-weight: 600;
				width: 40px;
				padding: 12px 0;
				line-height: 1;
				text-align: center;
				display: block;
				border-radius: 50%;
			}
			h3 {
				background-color: var(--tj-color-theme-bg-dark-light);
				font-size: 20px;
				font-weight: 500;
				line-height: 1;
				text-align: center;
				padding: 20px;
				margin-bottom: 0;
				width: 155px;
				position: absolute;
				left: 50%;
				top: -15px;
				transform: translate(-50%, -100%);
				visibility: hidden;
				opacity: 0;
				&:before {
					background-color: var(--tj-color-theme-bg-dark-light);
					width: 15px;
					height: 15px;
					content: "";
					position: absolute;
					left: 50%;
					bottom: -7.5px;
					transform: translateX(-50%) rotate(45deg);
				}
				span {
					display: block;
					font-size: 18px;
					margin-top: 10px;
				}
			}
			&:hover h3 {
				visibility: visible;
				opacity: 1;
			}
		}
	}
	.faq-content {
		background-color: var(--tj-color-theme-bg-dark-light);
		padding: 30px;
		width: 400px;
		border-radius: 5px;
		@media #{$md} {
			width: 100%;
		}
		.accordion-item {
			background-color: transparent;
			border: none;
			&:not(:last-of-type) {
				margin-bottom: 20px;
				padding-bottom: 20px;
				border-bottom: 1px solid var(--tj-color-border-7);
			}
			.accordion-button {
				background-color: transparent;
				color: var(--tj-color-common-white);
				font-size: 20px;
				padding: 0;
				&:focus {
					box-shadow: none;
				}
				&:not(.collapsed) {
					color: var(--tj-color-theme-primary);
					line-height: 1;
					margin-bottom: 15px;
					box-shadow: none;
				}
				&:after {
					background-image: none;
					font-family: "Font Awesome 6 Pro";
					content: "\2b";
					font-weight: 500;
					height: auto;
					width: auto;
					font-size: 16px;
				}
				&:not(.collapsed):after {
					content: "\f068";
					transform: rotate(0);
				}
			}
			.accordion-collapse {
				.accordion-body {
					position: relative;
					padding: 0;
					z-index: 2;
				}
			}
		}
	}
}

@-webkit-keyframes ripple_white {
	0% {
		-webkit-box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.08),
			0px 0px 0px 5px rgba(255, 255, 255, 0.08),
			0px 0px 0px 12px rgba(255, 255, 255, 0.08);
		box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.08),
			0px 0px 0px 5px rgba(255, 255, 255, 0.08),
			0px 0px 0px 12px rgba(255, 255, 255, 0.08);
	}

	100% {
		-webkit-box-shadow: 0px 0px 0px 5px rgba(255, 255, 255, 0.08),
			0px 0px 0px 12px rgba(255, 255, 255, 0.08),
			0px 0px 0px 18px rgba(255, 255, 255, 0);
		box-shadow: 0px 0px 0px 5px rgba(255, 255, 255, 0.08),
			0px 0px 0px 12px rgba(255, 255, 255, 0.08),
			0px 0px 0px 18px rgba(255, 255, 255, 0);
	}
}
@keyframes ripple_white {
	0% {
		-webkit-box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.2),
			0px 0px 0px 5px rgba(255, 255, 255, 0.2),
			0px 0px 0px 12px rgba(255, 255, 255, 0.2);

		box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.2),
			0px 0px 0px 5px rgba(255, 255, 255, 0.2),
			0px 0px 0px 12px rgba(255, 255, 255, 0.2);
	}

	100% {
		-webkit-box-shadow: 0px 0px 0px 5px rgba(255, 255, 255, 0.08),
			0px 0px 0px 12px rgba(255, 255, 255, 0.08),
			0px 0px 0px 18px rgba(255, 255, 255, 0);

		box-shadow: 0px 0px 0px 5px rgba(255, 255, 255, 0.08),
			0px 0px 0px 12px rgba(255, 255, 255, 0.08),
			0px 0px 0px 18px rgba(255, 255, 255, 0);
	}
}

.faq-section-2 {
	padding-bottom: 100px;
	@media #{$md} {
		padding-bottom: 50px;
	}
	@media #{$sm} {
		padding-bottom: 40px;
	}
}
.faq-info {
	.accordion {
		.accordion-item {
			background-color: transparent;
			border: 1px solid var(--tj-color-border-5);
			margin-bottom: 20px;
			border: 1px solid var(--tj-color-border-5);
			.accordion-button {
				background-color: transparent;
				font-family: var(--tj-ff-body);
				color: var(--tj-color-grey-9);
				font-size: 20px;
				box-shadow: none;
				&:not(.collapsed) {
					background-color: var(--tj-color-theme-bg-dark-light);
					color: var(--tj-color-theme-primary);
					box-shadow: none;
				}
				&:after {
					background-image: none;
					font-family: "Font Awesome 6 Pro";
					content: "\f078";
					font-weight: 500;
					height: auto;
					width: auto;
					font-size: 16px;
				}
				&:not(.collapsed):after {
					content: "\f077";
					color: var(--tj-color-theme-primary);
					transform: rotate(0);
				}
			}
			.accordion-body {
				&:not(.collapsed) {
					background-color: var(--tj-color-theme-bg-dark-light);
					padding-top: 0;
				}
			}
		}
	}
}

/* !END: Theme Faq CSS */
