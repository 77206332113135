@use "../utilities" as *;

/**----------------------------------------
START: Theme Contact CSS
----------------------------------------*/
.contact-wrap {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    @media #{$sm} {
        grid-template-columns: 1fr;
        grid-row-gap: 30px;
    }
}
.contact-item {
    border-right: 1px solid var(--tj-color-border-5);
    &:last-child {
        border: none;
    }
    .contact-icon {
        font-size: 45px;
        color: var(--tj-color-theme-primary);
        line-height: 1;
        margin-bottom: 15px;
    }
    .contact-title {
        color: var(--tj-color-grey-9);
        line-height: 1;
        margin-bottom: 15px;
    }
    .contact-list {
        list-style: none;
        li {
            a {
                font-size: 18px;
            }
        }
    }
    p {
        font-size: 18px;
        max-width: 190px;
        width: 100%;
        margin: 0 auto;
        margin-bottom: 0;
    }
}

.map-wrapper {
    iframe {
        height: 525px;
        width: 100%;
        @media #{$sm, $xs} {
            height: 450px;
        }
    }
}
/* !END: Theme Contact CSS */
