@use "../utilities" as *;

/**----------------------------------------
START: Theme Testimonial CSS
----------------------------------------*/
.testimonial-section {
    .testimonial-carousel {
        .testi-item {
            .testi-content {
                background-color: var(--tj-color-theme-bg-dark-deep);
                padding: 40px 30px;
                p {
                    margin-bottom: 0;
                }
            }
            .testi-author {
                background-color: var(--tj-color-theme-bg-dark-light-2);
                padding: 40px 30px;
                column-gap: 20px;
                position: relative;
                .author-thumb {
                    height: 80px;
                    width: 80px;
                    img {
                        border-radius: 50%;
                    }
                }
                .author-content {
                    .author-name {
                        font-family: var(--tj-ff-body);
                        color: var(--tj-color-grey-9);
                        font-size: 22px;
                    }
                }
                .author-quote {
                    position: absolute;
                    top: -27.5px;
                    right: 40px;
                }
            }
        }
        .owl-nav {
            display: none;
        }
        .owl-dots {
            display: block;
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 10px;
            width: 100%;
            margin-top: 50px;
            span {
                background-color: #fff;
                height: 3px;
                width: 25px;
                display: block;
                border-radius: 30px;
            }
            .active {
                span {
                    background-color: var(--tj-color-theme-primary);
                }
            }
        }
    }
}

/* !END: Theme Testimonial CSS */
