@use "../utilities" as *;

/**----------------------------------------
START: Theme Counter CSS
----------------------------------------*/
.counter-section {
    background-color: var(--tj-color-theme-bg-dark-light);
    padding: 105px 0 95px 0;
    @media #{$lg} {
        padding: 90px 0;
    }
    @media #{$md} {
        padding: 50px 0 25px 0;
    }
    @media #{$sm} {
        padding: 30px 0 10px 0;
    }
}

.counter-item {
    position: relative;
    @media #{$md} {
        margin-bottom: 20px;
    }
    .counter-title {
        font-family: var(--tj-ff-heading);
        color: var(--tj-color-theme-primary);
        font-size: 80px;
        font-weight: 400;
        margin-bottom: 0;
        opacity: 0.5;
        @media #{$lg} {
            font-size: 60px;
        }
        @media #{$md} {
            font-size: 70px;
        }
        @media #{$sm} {
            font-size: 50px;
        }
        .odometer.odometer-auto-theme {
            font-family: inherit;
            line-height: 1;
        }
    }
    .counter-content {
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        .counter-text {
            font-family: var(--tj-ff-body);
            font-size: 18px;
            font-weight: 400;
            letter-spacing: 8px;
            margin-bottom: 5px;
            line-height: 1;
            @media #{$sm} {
                font-size: 12px;
                letter-spacing: 4px;
            }
        }
    }
}
@media #{$sm} {
    .col-sm-6 {
        flex: 0 0 auto;
        width: 50%;
    }
}

.counter-2 {
    padding: 75px 0 70px 0;
    @media #{$md} {
        padding: 50px 0;
    }
    @media #{$sm} {
        padding: 30px 0 10px 0;
    }
}

.counter-item.item-2 {
    .counter-title {
        @media #{$md} {
            font-size: 60px;
        }
        @media #{$sm} {
            font-size: 50px;
        }
        .odometer-formatting-mark {
            display: none;
        }
    }
    .counter-text {
        letter-spacing: 4px;
    }
}

.counter-3 {
    .counter-container {
        max-width: 1530px;
        margin: 0 auto;
        padding: 0 15px;
        width: 100%;
    }
}
/* !END: Theme Counter CSS */
