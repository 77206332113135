@use "../utilities" as *;

/*-----------------------------------------------------------------------------------

    [Master Stylesheet]
    Theme Name: Beauly - Single Property HTML Template
    Theme URI: https://themejunction.net/html/Beauly/demo/
    Author: Theme-Junction
    Author URI: https://themeforest.net/user/theme-junction
    Description: Beauly - Single Property HTML Template

-----------------------------------------------------------------------------------


/************ TABLE OF CONTENTS ***************

  Theme Header CSS
  Theme About CSS
  Theme Amenities CSS
  Theme Apartment Info CSS
  Theme Apartment CSS
  Theme Blog CSS
  Theme Contact CSS
  Theme Counter CSS
  Theme Cover CSS
  Theme CTA CSS
  Theme Faq CSS
  Theme Floor CSS
  Theme Follow CSS
  Theme footer CSS
  Theme Gallery CSS
  Theme Hero CSS
  Theme Page Header CSS
  Theme Plan CSS
  Theme Preloder CSS
  Theme Project Filter CSS
  Theme Project CSS
  Theme Request CSS
  Theme Scroll CSS
  Theme Service CSS
  Theme Sponsor CSS
  Theme Subscribe CSS
  Theme Team CSS
  Theme Testimonial CSS

**********************************************/

/**----------------------------------------
START: Theme Default CSS
----------------------------------------*/
@import url($beauly-font-url);

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/**
  Typography CSS
*/
body {
    font-family: var(--tj-ff-body);
    font-size: var(--tj-fs-body);
    font-weight: normal;
    color: var(--tj-color-text-body);
}

a {
    text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: var(--tj-ff-heading);
    color: var(--tj-color-common-white);
    margin-top: 0;
    font-weight: var(--tj-fw-sbold);
    line-height: 1.2;
}

h1 {
    font-size: var(--tj-fs-h1);
}

h2 {
    font-size: var(--tj-fs-h2);
}

h3 {
    font-size: var(--tj-fs-h3);
}

h4 {
    font-size: var(--tj-fs-h4);
}

h5 {
    font-size: var(--tj-fs-h5);
}

h6 {
    font-size: var(--tj-fs-h6);
}

ul {
    margin: 0;
    padding: 0;
}

p {
    font-family: var(--tj-ff-p);
    font-size: 16px;
    font-weight: var(--tj-fw-normal);
    color: var(--tj-color-text-body);
    margin-bottom: 15px;
    line-height: 26px;
}

.btn,
button,
span,
p,
input,
select,
textarea,
li,
img,
svg path,
h1,
h2,
h3,
h4,
h5,
h6 {
    @include transition(all 0.3s ease-in-out 0s);
}

a:focus,
.button:focus {
    text-decoration: none;
    outline: none;
}

a:focus,
a:hover {
    color: inherit;
    text-decoration: none;
}

a,
button {
    color: inherit;
    outline: none;
    border: none;
    background: transparent;
}

button:hover {
    cursor: pointer;
}

button:focus {
    outline: 0;
    border: 0;
}

.uppercase {
    text-transform: uppercase;
}
.capitalize {
    text-transform: capitalize;
}

input {
    outline: none;
}

input[type="color"] {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background: none;
    border: 0;
    cursor: pointer;
    height: 100%;
    width: 100%;
    padding: 0;
    border-radius: 50%;
}

*::-moz-selection {
    background: var(--tj-color-common-black);
    color: var(--tj-color-common-white);
    text-shadow: none;
}
::-moz-selection {
    background: var(--tj-color-common-black);
    color: var(--tj-color-common-white);
    text-shadow: none;
}
::selection {
    background: var(--tj-color-common-black);
    color: var(--tj-color-common-white);
    text-shadow: none;
}

*::-moz-placeholder {
    color: var(--tj-color-common-black);
    font-size: var(--tj-fs-body);
    opacity: 1;
}
*::placeholder {
    color: var(--tj-color-common-black);
    font-size: var(--tj-fs-body);
    opacity: 1;
}

/** Background Colors **/

.bg-dark-deep {
    background-color: var(--tj-color-theme-bg-dark-deep);
}

.bg-dark-light {
    background-color: var(--tj-color-theme-bg-dark-light);
}

/** Common Classes CSS **/
.w-img {
    & img {
        width: 100%;
    }
}
.m-img {
    & img {
        max-width: 100%;
    }
}
img {
    max-width: 100%;
}
.fix {
    overflow: hidden;
}
.clear {
    clear: both;
}
.f-left {
    float: left;
}
.f-right {
    float: right;
}
.z-index-1 {
    z-index: 1;
}
.z-index-11 {
    z-index: 11;
}
.overflow-y-visible {
    overflow-x: hidden;
    overflow-y: visible;
}
.p-relative {
    position: relative;
}
.p-absolute {
    position: absolute;
}
.include-bg {
    @include background();
}
.b-radius {
    border-radius: 6px;
}
.padding {
    padding: 120px 0;
    @media #{$md} {
        padding: 70px 0;
    }
    @media #{$sm} {
        padding: 60px 0;
    }
}

@media #{$md} {
    .sm-padding {
        padding: 15px;
    }
}
.padding-15 {
    padding: 15px !important;
}

.pt-0 {
    padding-top: 0;
}

.pb-0 {
    padding-bottom: 0;
}

/* Section Heading */

.section-heading {
    margin-bottom: 60px;
    .sub-heading {
        color: var(--tj-color-theme-primary);
        margin-bottom: 22px;
        line-height: 1;
    }
    .section-title {
        font-weight: var(--tj-fw-bold);
        margin-bottom: 20px;
        @media #{$md} {
            font-size: 44px;
        }
        @media #{$sm} {
            font-size: 32px;
            margin-bottom: 10px;
        }
    }
    p {
        font-size: 18px;
        max-width: 620px;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        &:last-child {
            margin-bottom: 0;
        }
        @media #{$sm} {
            br {
                display: none;
            }
        }
    }
}

@media #{$md} {
    .section-heading.mb-60 {
        margin-bottom: 40px;
    }
    .section-heading {
        margin-bottom: 40px;
    }
}

/* Margin Class */
.mt-10 {
    margin-top: 10px;
}

.mt-15 {
    margin-top: 15px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-25 {
    margin-top: 25px;
}

.mt-30 {
    margin-top: 30px;
}

.mt-35 {
    margin-top: 35px;
}

.mt-40 {
    margin-top: 40px;
}

.mt-45 {
    margin-top: 45px;
}

.mt-50 {
    margin-top: 50px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mb-15 {
    margin-bottom: 15px;
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-25 {
    margin-bottom: 25px;
}

.mb-30 {
    margin-bottom: 30px;
}

.mb-35 {
    margin-bottom: 35px;
}

.mb-40 {
    margin-bottom: 40px;
}

.mb-45 {
    margin-bottom: 45px;
}

.mb-50 {
    margin-bottom: 50px;
}

.mb-60 {
    margin-bottom: 60px;
}

.mb-90 {
    margin-bottom: 90px;
}

/* overflow */

.oh {
    overflow: hidden;
}

// video area
.video-section {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 600px;
    @media #{$md} {
        height: 400px;
    }
}
